import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLifeRing,
  faPhone,
  faExternalLinkAlt,
  faHome,
  faChevronLeft,
  faChevronRight, faSearch, faTimes,
} from "@fortawesome/free-solid-svg-icons"


class supportSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: "",
    }
  }

  render() {
    const { articles } = this.props.pageContext

    return (
      <Layout container={false} isDark={true} hasDarkColorScheme={"low-contrast"}>
        <SEO title={"Support › Artikel suchen "} />

        <div className="container-fluid hero-big hero" style={{ height: "250px", backgroundPosition: "top center" }}
             id="support">
          <div className="text-center">
            <br />
            <br />
            <h1>Support</h1>
          </div>
        </div>

        <div className={"search-results"}>
          <div className="container" style={{ paddingBottom: "95px" }}>

            <div className="breadcrumb">
              <div>
                <Link to="/support"><FontAwesomeIcon icon={faChevronLeft} /> Support</Link>
                &nbsp;
                /
                &nbsp;
              </div>
              <input autoFocus={true} className={"search"} value={this.state.search} onChange={(e)=>this.setState({search:e.target.value})} placeholder={"Suchbegriff eingeben ..."} />
              {
                this.state.search.length > 0 ?
                  <a href={"#"} onClick={()=>this.setState({search: ""})}><FontAwesomeIcon icon={faTimes} /> Leeren</a>
                  :
                  <a href={"#"}><FontAwesomeIcon icon={faSearch} /> Suchen</a>
              }
            </div>

            {
              articles.filter(article=>this.state.search.length === 0 || JSON.stringify(article).toLowerCase().includes(this.state.search.toLowerCase())).map((item, index) => {
                return (
                  <a href={"/support" + item.path} key={index}>
                    <div className="card card-horizontal">
                      <h1>{item.title}</h1>
                      <p>{item.content?.[0]?.tags?.find(tag => tag.tag === "p")?.value}</p>
                    </div>
                  </a>
                )
              }) || "Keine"
            }
          </div>
        </div>

      </Layout>

    )
  }
}

export default supportSearch
